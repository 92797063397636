import React, { useState, useEffect, useMemo } from "react";
import { useTheme, useMediaQuery, Collapse } from "@mui/material";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../../contexts/AuthContext";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Public as PublicIcon,
  RequestQuoteOutlined as RequestQuoteOutlinedIcon,
  AdjustOutlined as AdjustOutlinedIcon,
  BuildOutlined as BuildOutlinedIcon,
  AccountCircle as AccountCircleIcon,
  AccountBalance as AccountBalanceIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  Inbox as InboxIcon,
  SportsEsports as SportsEsportsIcon,
  Casino as CasinoIcon,
  SportsBaseball as SportsBaseballIcon,
  ReportProblem as ReportProblemIcon,
  MonetizationOn as MonetizationOnIcon,
  People as PeopleIcon,
  Payment as PaymentIcon,
  ManageAccounts as ManageAccountsIcon,
  Settings as SettingsIcon,
  CardGiftcard as GiftIcon,
  Notifications as NotificationsIcon,
  AccountBalanceWallet as WalletIcon,
  ManageHistory as ManageHistoryIcon,
  Help as HelpIcon,
  Update as UpdateIcon,
  BorderColor as BorderColorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Games as GamesIcon,
} from "@mui/icons-material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { domain } from "../../Components/config";

const drawerWidth = 240;

const getDrawerItems = (accountType) => {
  const gameManagementItems = [
    { text: "Wingo Admin", icon: <InboxIcon />, link: "/wingo-admin" },
    { text: "K3 Admin", icon: <CasinoIcon />, link: "/k3-admin" },
    { text: "5D Admin", icon: <SportsBaseballIcon />, link: "/5d-admin" },
  ];

  const rechargeItems = [
    {
      text: "Pending Deposit",
      icon: <AdjustOutlinedIcon />,
      link: "/pending-recharge-admin",
    },
    {
      text: "Approved Deposit",
      icon: <AdjustOutlinedIcon />,
      link: "/recharge-admin",
    },
  ];

  const withdrawItems = [
    {
      text: "Pending Withdraw",
      icon: <AdjustOutlinedIcon />,
      link: "/withdraw-admin-status",
    },
    {
      text: "Approved Withdraw",
      icon: <AdjustOutlinedIcon />,
      link: "/withdraw-admin",
    },
  ];

  const memberItems = [
    {
      text: "Active User",
      icon: <AdjustOutlinedIcon />,
      link: "/members",
    },
    {
      text: "Banned User",
      icon: <AdjustOutlinedIcon />,
      link: "/ban-members",
    },
    { text: "Subordinate", icon: <AccountTreeIcon />, link: "/Subordinate" },
  ];

  const addonItems = [
    { text: "Create Giftcode", icon: <GiftIcon />, link: "/create-coupon" },
    {
      text: "Notifications",
      icon: <NotificationsIcon />,
      link: "/notifications-admin",
    },
  ];

  const settingsItems = [
    { text: "Update", icon: <SettingsIcon />, link: "/settings-admin" },
    {
      text: "Withdrawl Settings",
      icon: <SettingsIcon />,
      link: "/withdrawl-limits",
    },
    {
      text: "Activity Setting",
      icon: <UpdateIcon />,
      link: "/admin/activity-award",
    },
    { text: "Lucky Spin", icon: <UpdateIcon />, link: "/lucky-spin" },
    {
      text: "Inviation Bonus",
      icon: <UpdateIcon />,
      link: "/admin/invitation-bonus",
    },
    {
      text: "First Deposit Bonus",
      icon: <GiftIcon />,
      link: "/bonus-settings",
    },
  ];
  const allItems = [
    { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
    {
      text: "Manage Games",
      icon: <SportsEsportsIcon />,
      subItems: gameManagementItems,
    },
    {
      text: "Illegal Bets",
      icon: <ReportProblemIcon />,
      link: "/illegal-bets",
    },
    { text: "Profit/Loss", icon: <MonetizationOnIcon />, link: "/profit-loss" },
    {
      text: "Deposits",
      icon: <RequestQuoteOutlinedIcon />,
      subItems: rechargeItems,
    },
    {
      text: "Withdrawals",
      icon: <AccountBalanceIcon />,
      subItems: withdrawItems,
    },
    {
      text: "Manage User",
      icon: <PeopleIcon />,
      subItems: memberItems,
    },
    {
      text: "Additional",
      icon: <GiftIcon />,
      subItems: addonItems,
    },
    { text: "VIP Level", icon: <ManageAccountsIcon />, link: "/vip-levels" },
    {
      text: "Settings",
      icon: <BuildOutlinedIcon />,
      subItems: settingsItems,
      link: "/System",
    },
    {
      text: "Create Salary",
      icon: <MonetizationOnIcon />,
      link: "/create-salary",
    },
    {
      text: "Update Turn Over",
      icon: <ManageHistoryIcon />,
      link: "/update-turn-over",
    },
    { text: "Create User", icon: <HelpIcon />, link: "/create-user-account" },

    { text: "Support Ticket", icon: <HelpIcon />, link: "/support-admin" },
    // {
    //   text: "Edit Bank Details",
    //   icon: <BorderColorIcon />,
    //   link: "/edit-user-bank-details",
    // },
  ];

  const itemSets = {
    Admin: allItems,
    FinanceHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Illegal Bets",
        icon: <ReportProblemIcon />,
        link: "/illegal-bets",
      },
    ],
    GameHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Manage Games",
        icon: <GamesIcon />,
        subItems: gameManagementItems,
      },
    ],
    RechargeHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Deposits",
        icon: <RequestQuoteOutlinedIcon />,
        subItems: rechargeItems,
      },
    ],
    WithdrawHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Withdrawals",
        icon: <AccountBalanceIcon />,
        subItems: withdrawItems,
      },
    ],
    MemberHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Manage User",
        icon: <PeopleIcon />,
        subItems: memberItems,
      },
    ],
    AddonHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Additional",
        icon: <GiftIcon />,
        subItems: memberItems,
      },
    ],
    SettingsHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      { text: "VIP Level", icon: <ManageAccountsIcon />, link: "/vip-levels" },
      { text: "Update", icon: <SettingsIcon />, link: "/settings-admin" },
      {
        text: "Withdrawal Settings",
        icon: <SettingsIcon />,
        link: "/withdrawl-limits",
      },
      {
        text: "Activity Setting",
        icon: <UpdateIcon />,
        link: "/admin/activity-award",
      },
      { text: "Lucky Spin", icon: <UpdateIcon />, link: "/lucky-spin" },
      {
        text: "Invitation Bonus",
        icon: <UpdateIcon />,
        link: "/admin/invitation-bonus",
      },
      {
        text: "First Deposit Bonus",
        icon: <GiftIcon />,
        link: "/bonus-settings",
      },
    ],
    AdditionalHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      {
        text: "Notifications",
        icon: <NotificationsIcon />,
        link: "/notifications-admin",
      },
      {
        text: "Create Salary",
        icon: <MonetizationOnIcon />,
        link: "/create-salary",
      },
      { text: "Create Gift Code", icon: <GiftIcon />, link: "/create-coupon" },
    ],
    SupportHead: [
      { text: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
      { text: "Members", icon: <PeopleIcon />, link: "/members" },
      { text: "Support", icon: <HelpIcon />, link: "/support-admin" },
      {
        text: "Edit Bank Details",
        icon: <BorderColorIcon />,
        link: "/edit-user-bank-details",
      },
    ],
  };

  return itemSets[accountType] || [];
};

const DrawerItem = React.memo(({ item, isActive, level = 0 }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (item.subItems) {
      setOpen(!open);
    }
  };

  const indentPadding = level * 2;

  if (item.subItems) {
    return (
      <>
        <ListItem
          button
          onClick={handleClick}
          sx={{
            pl: 2 + indentPadding,
            background: isActive ? "#4634ff" : "inherit",
            "&:hover": {
              background: "#262795",
            },
            color: "white",
          }}
        >
          <ListItemIcon>
            {React.cloneElement(item.icon, {
              sx: { color: "white" },
            })}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontWeight: "500" }}>
                {item.text}
              </Typography>
            }
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subItems.map((subItem) => (
              <DrawerItem
                key={subItem.text}
                item={subItem}
                isActive={isActive}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem
      button
      component={Link}
      to={item.link}
      sx={{
        pl: 2 + indentPadding,
        background: isActive ? "#4634ff" : "inherit",
        "&:hover": {
          background: "#262795",
        },
        color: "white",
      }}
    >
      <ListItemIcon>
        {React.cloneElement(item.icon, {
          sx: { color: "white" },
        })}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            {item.text}
          </Typography>
        }
      />
    </ListItem>
  );
});

const DrawerContent = React.memo(({ items, currentPath }) => (
  <List>
    {items.map((item) => (
      <DrawerItem
        key={item.text}
        item={item}
        isActive={
          currentPath === item.link ||
          item.subItems?.some((subItem) => currentPath === subItem.link)
        }
      />
    ))}
  </List>
));

const ProfileMenu = React.memo(({ anchorEl, handleClose, handleLogout }) => (
  <Menu
    id="profile-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={handleClose}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </MenuItem>
    <MenuItem onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Log Out" />
    </MenuItem>
  </Menu>
));

const AdminPanel = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [accountType, setAccountType] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleProfileMenuClose = () => setAnchorEl(null);
  const handleGoHome = () => {
    navigate("/home");
  };
  const handleGoSetting = () => {
    navigate("/System");
  };
  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/user`, {
          withCredentials: true,
        });
        setAccountType(response.data.user.accountType);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get(`${domain}/api/site-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        setLogoUrl(response.data.logoUrl);
      } catch (error) {
        console.error("Error fetching the logo:", error);
      }
    };
    fetchLogo();
  }, []);

  const drawerItems = useMemo(() => getDrawerItems(accountType), [accountType]);

  const drawer = useMemo(
    () => (
      <div>
        <Toolbar />
        <Divider />
        <DrawerContent items={drawerItems} currentPath={location.pathname} />
      </div>
    ),
    [drawerItems, location.pathname]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "#071251",
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, color: "white" }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          >
            <Box
              component="img"
              sx={{
                height: 50,
                marginRight: 1,
              }}
              alt="Logo"
              src={logoUrl || "../../../assets/damanilogo.png"}
            />
          </Typography>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleGoHome}
            sx={{ color: "white" }}
          >
            <PublicIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleGoSetting}
            sx={{ color: "white", transform: "scaleX(-1)" }}
          >
            <BuildOutlinedIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            // onClick={handleProfileMenuOpen}
            sx={{ color: "white" }}
          >
            <NotificationsIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            sx={{ color: "white" }}
          >
            <AccountCircleIcon />
          </IconButton>
          <ProfileMenu
            anchorEl={anchorEl}
            handleClose={handleProfileMenuClose}
            handleLogout={handleLogout}
          />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
          overflowY: "hidden",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#071251",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
              },
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          bgcolor: "#f3f3f9",
          minHeight: "100vh",
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
            md: `100%`,
            sm: `100%`,
            xs: `100%`,
          },
          mt: 3,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(AdminPanel);
